import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputSlider from 'components/CarLoanCalculator/InputSliderNew/InputSlider';

import { calculateDownpaymentFromPercent } from '../utils/calculateMonthlyCost';

import styles from './LoanModifier.module.scss';

interface IProps {
  isInverted?: boolean;
  totalCost: number;
  initInvestmentRatio?: number;
  stepSize?: number;
}

export enum DownpaymentDefaults {
  InvestmentRatioMin = 0.2,
  InvestmentRatioMax = 0.5,
}

export const LoanModifier: React.FC<IProps> = ({
  isInverted,
  totalCost,
  initInvestmentRatio = DownpaymentDefaults.InvestmentRatioMin,
  stepSize = 1,
}) => {
  const { watch, setValue } = useFormContext();
  const residual = watch('residual_percent');
  const investmentPercent = watch('investment_percent');
  const minAllowedValue = totalCost * initInvestmentRatio;
  const maxDownpaymentRatio = Math.min(1 - residual / 100, DownpaymentDefaults.InvestmentRatioMax);

  const maxAllowedValue = totalCost * maxDownpaymentRatio;

  const handleInvestmentChange = (value: number) => {
    // Convert value to percentage
    const newInvestmentPercent = (Math.round(value) / totalCost) * 100;
    setValue('investment_percent', newInvestmentPercent);
  };

  const handleInvestmentPercentChange = (value: number) => {
    setValue('investment_percent', value);
  };

  const investment = calculateDownpaymentFromPercent(totalCost, investmentPercent);

  return (
    <div className={styles['loan-slider']}>
      <InputSlider
        className="mb-2"
        size="large"
        min={minAllowedValue}
        max={maxAllowedValue}
        value={investment}
        step={stepSize}
        onChange={handleInvestmentChange}
        suffix=" kr"
        sliderSuffix=" %"
        label="Kontantinsats"
        isInverted={isInverted}
        sliderValue={investmentPercent}
        sliderMin={Math.round(initInvestmentRatio * 100)}
        sliderMax={Math.round(maxDownpaymentRatio * 100)}
        onSliderChange={handleInvestmentPercentChange}
      />
    </div>
  );
};
