import { ClientApiService } from 'services/ClientApiService';
import { ICoupon, ICouponData } from 'types/CouponCode';

const initCouponData: ICouponData = {
  coupon: {
    percentReduction: {
      value: 0,
      active: false,
    },
    valueReduction: {
      value: 0,
      active: false,
    },
    warrantyIncluded: {
      active: false,
    },
  },
  status: {
    title: '',
    errors: [],
  },
};

const mapCouponResponse = (response: { payload: any }): ICoupon => {
  const { payload } = response;

  if (!payload) return initCouponData.coupon;

  const {
    percentage_reduction: percentReduction,
    value_reduction: valueReduction,
    warranty_included: warrantyIncluded,
  } = payload;

  return {
    percentReduction: {
      value: percentReduction / 100 || 0,
      active: !!percentReduction,
    },
    valueReduction: {
      value: valueReduction || 0,
      active: !!valueReduction,
    },
    warrantyIncluded: {
      active: !!warrantyIncluded,
    },
  };
};

export const fetchCouponCode = async (code: string, elementName: string) => {
  if (elementName !== 'campaign-code') return null;
  if (code === '') return null;

  // request
  const response = await ClientApiService.proxyRequest({
    method: 'getCouponCode',
    payload: { code },
  });
  const responseJson = await response.json();

  // handle error
  if (response.status !== 200) {
    return {
      ...initCouponData,
      status: {
        title: 'Något blev fel',
        errors: responseJson.body.errors,
      },
    };
  }

  return {
    ...initCouponData,
    coupon: mapCouponResponse(responseJson.body),
  };
};
