import { ClientApiService } from 'services/ClientApiService';

export const handleNotAuthenticatedUser = async (status: number, notAuthenticated: boolean) => {
  if (status === 401 && notAuthenticated) {
    await ClientApiService.logout();

    window.location.href = '/app/login';

    return false;
  }
  return true;
};
