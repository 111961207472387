import { VehicleType } from 'enums/VehicleType';
import { LoanType } from 'enums/LoanType';
import { ICoupon } from 'types/CouponCode';
import PMT from 'utils/getPMT';

import { getResidualPercent } from './getResidualPercent';

export const INTEREST_DEFAULT = (process.env.NEXT_PUBLIC_LOAN_INTEREST as unknown as number) / 100;
export const BLANCO_INTEREST = 0.0795;
export const WARRANTY_DEFAULT = 3995;

const getWarranty = (warrantyIsChecked: boolean | undefined, coupon: ICoupon | undefined) => {
  const WARRENTY_ZERO = 0;

  if (!warrantyIsChecked) return WARRENTY_ZERO;

  return coupon?.warrantyIncluded?.active ? WARRENTY_ZERO : WARRANTY_DEFAULT;
};

const getLoanAmount = (
  amount: number,
  downpayment: number,
  warranty: number,
  coupon: ICoupon | undefined
) => {
  const loanAmount = amount - downpayment + warranty;

  return coupon?.valueReduction?.active === true
    ? loanAmount - coupon.valueReduction.value
    : loanAmount;
};

const getInterest = (coupon: ICoupon | undefined) =>
  coupon?.percentReduction?.active === true && coupon.percentReduction.value
    ? INTEREST_DEFAULT - coupon.percentReduction.value
    : INTEREST_DEFAULT;

interface ICalculateMonthlyCost {
  amount: number;
  months: number;
  downpayment: number;
  warrantyIsChecked?: boolean;
  coupon?: ICoupon;
  loanType?: LoanType;
  vehicleType?: VehicleType;
  residual?: number;
}

export const calculateMonthlyCost = ({
  amount,
  months,
  downpayment,
  warrantyIsChecked,
  coupon,
  loanType = LoanType.Ecster,
  vehicleType = VehicleType.Car,
  residual = undefined, // todo: rewrite the inputs as an object
}: ICalculateMonthlyCost): number => {
  const residualPercent =
    typeof residual === 'number'
      ? residual / 100
      : getResidualPercent(months, loanType, vehicleType);

  const warranty = getWarranty(warrantyIsChecked, coupon);
  const loanAmount = getLoanAmount(amount, downpayment, warranty, coupon);
  const interest = getInterest(coupon);
  const residualAmount = (amount + warranty) * residualPercent;
  let totalPayAmountPerMonth = 0;

  if (loanType === LoanType.Ecster || loanType === LoanType.Moank) {
    const yearlyInterestAmount = loanAmount * interest;
    const monthlyInterestAmount = Math.round(yearlyInterestAmount / 12);
    const monthlyAmortization = (loanAmount - residualAmount) / months;
    totalPayAmountPerMonth = Math.ceil(monthlyInterestAmount + monthlyAmortization);
  } else {
    totalPayAmountPerMonth = Math.ceil(
      PMT(
        interest / 12,
        months,
        -loanAmount,
        residualAmount,
        !!(loanType === LoanType.NordeaLeasing)
      )
    );
  }

  return totalPayAmountPerMonth > 0 ? totalPayAmountPerMonth : 0;
};

interface ICalculateMonthlyCostWInterest {
  (
    amount: number,
    downPayment: number,
    months: number,
    residualPercent?: number,
    interest?: number
  ): number;
}

/**
 * @description Calculates the amount needed to be paid in
 * the first month of a loan using a straight amortization
 */
export const calculateMonthlyCostWInterest: ICalculateMonthlyCostWInterest = (
  amount = 0,
  downPayment = 0,
  months = 0,
  residualPercent = 0,
  interest = 0
) => {
  const loanAmount = amount - downPayment;
  const yearlyInterestAmount = loanAmount * interest;
  const monthlyInterest = Math.round(yearlyInterestAmount / 12);
  const residualAmount = amount * residualPercent;
  const monthlyAmount = (loanAmount - residualAmount) / months;
  const totalPerMonth = Math.ceil(monthlyAmount + monthlyInterest);

  return totalPerMonth;
};

export const calculateDownpaymentFromPercent = (totalCost: number, investmentPercent: number) =>
  Math.round(totalCost * (investmentPercent / 100));
