export const companyKycQuestions = [
  {
    id: 1,
    type: 'text',
    label: 'I vilket land är företaget registrerat?',
    question: 'I vilket land är företaget registrerat?',
    name: 'registered_country',
    required: true,
  },
  {
    id: 2,
    type: 'radio',
    label: 'Bedrivs företagets verksamhet huvudsakligen inom EU/EEA?',
    question: 'Bedrivs företagets verksamhet huvudsakligen inom EU/EEA?',
    name: 'conducted_within_ue_eea',
    options: [
      { title: 'Ja', value: 'yes', name: 'conducted_within_ue_eea', nextStep: 4 },
      { title: 'Nej', value: 'no', name: 'conducted_within_ue_eea' },
    ],
    required: true,
  },
  {
    id: 3,
    type: 'text',
    label: 'I vilket land är bedrivs företagets verksamhet huvudsakligen?',
    question: 'I vilket land är bedrivs företagets verksamhet huvudsakligen?',
    name: 'main_conducted_country',
    required: true,
  },
  {
    id: 4,
    type: 'radio',
    label: 'Är företagets skattemässiga hemvist Sverige?',
    question: 'Är företagets skattemässiga hemvist Sverige?',
    name: 'company_tax_residence_Sweden',
    options: [
      { title: 'Ja', value: 'yes', name: 'company_tax_residence_Sweden', nextStep: 6 },
      { title: 'Nej', value: 'no', name: 'company_tax_residence_Sweden' },
    ],
    required: true,
  },
  {
    id: 5,
    type: 'text',
    label: 'Vilket land är företagets skattemässiga hemvist?',
    question: 'Vilket land är företagets skattemässiga hemvist?',
    name: 'company_tax_domicile',
    required: true,
  },
  {
    id: 6,
    type: 'radio',
    label: 'Ägs delar eller hela företaget av annat eller andra företag?',
    question: 'Ägs delar eller hela företaget av annat eller andra företag?',
    name: 'owned_by_other_companies',
    options: [
      { title: 'Ja', value: 'yes', name: 'owned_by_other_companies' },
      { title: 'Nej', value: 'no', name: 'owned_by_other_companies', nextStep: 8 },
    ],
    required: true,
  },
  {
    id: 7,
    type: 'text',
    label: 'Vilket eller vilka företag äger delar eller hela företaget?',
    question: 'Vilket eller vilka företag äger delar eller hela företaget?',
    name: 'company_owners',
    required: true,
  },
  {
    id: 8,
    type: 'radio',
    label: 'Har det skett förändringar i företagets verkliga huvudmän?',
    question: 'Har det skett förändringar i företagets verkliga huvudmän?',
    name: 'real_principals_changes',
    options: [
      { title: 'Ja', value: 'yes', name: 'real_principals_changes' },
      { title: 'Nej', value: 'no', name: 'real_principals_changes', nextStep: 10 },
      {
        title: 'Företaget saknar verkliga huvudmän',
        value: 'no_principals',
        name: 'real_principals_changes',
        nextStep: 10,
      },
    ],
    required: true,
  },
  {
    id: 9,
    type: 'text',
    label: 'Vilka är förändringarna som skett?',
    question: 'Vilka är förändringarna som skett?',
    name: 'prinicpals_changes_description',
    required: true,
  },
  {
    id: 10,
    type: 'radio',
    label: 'Är någon av företagets verkliga huvudmän PEP?',
    question: 'Är någon av företagets verkliga huvudmän PEP?',
    name: 'beneficial_owners_pep',
    options: [
      { title: 'Ja', value: 'yes', name: 'beneficial_owners_pep' },
      { title: 'Nej', value: 'no', name: 'beneficial_owners_pep' },
    ],
    required: true,
  },
  {
    id: 11,
    type: 'radio',
    label: 'Är någon av företagets verkliga huvudmän närstående PEP?',
    question: 'Är någon av företagets verkliga huvudmän närstående PEP?',
    name: 'beneficial_owners_related_pep',
    options: [
      { title: 'Ja', value: 'yes', name: 'beneficial_owners_related_pe' },
      { title: 'Nej', value: 'no', name: 'beneficial_owners_related_pe' },
    ],
    required: true,
  },
  {
    id: 12,
    type: 'text',
    label: 'Övriga kommentarer',
    question: 'Övriga kommentarer',
    name: 'other_comments',
    required: false,
  },
];

export const privateKycQuestions = [
  {
    id: 1,
    question: 'Månadsinkomst före skatt',
    name: 'income',
  },
  {
    id: 2,
    question: 'Sysselsättning',
    name: 'employment',
  },
  {
    id: 3,
    question: 'Boendeform',
    name: 'living',
  },
  {
    id: 4,
    question: 'Antal vuxna i hushållet',
    name: 'number_of_adults',
  },
  {
    id: 5,
    question: 'Antal barn i hushållet',
    name: 'number_of_children',
  },
];
