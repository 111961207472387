import { LoanType } from 'enums/LoanType';
import { VehicleType } from 'enums/VehicleType';

export interface IDurationOptions {
  id: string;
  months: number;
  title: string;
}

export const allDurationOptions = [
  {
    id: 'one-year',
    months: 12,
    years: 1,
    title: '1 år',
  },
  {
    id: 'two-years',
    months: 24,
    years: 2,
    title: '2 år',
  },
  {
    id: 'three-years',
    months: 36,
    years: 3,
    title: '3 år',
  },
  {
    id: 'four-years',
    months: 48,
    years: 4,
    title: '4 år',
  },
  {
    id: 'five-years',
    months: 60,
    years: 5,
    title: '5 år',
  },
  {
    id: 'six-years',
    months: 72,
    years: 6,
    title: '6 år',
  },
  {
    id: 'seven-years',
    months: 84,
    years: 7,
    title: '7 år',
  },
  {
    id: 'eight-years',
    months: 96,
    years: 8,
    title: '8 år',
  },
];

export const durationOptions = [
  {
    id: 'two-years',
    months: 24,
    title: '2 år',
  },
  {
    id: 'three-years',
    months: 36,
    title: '3 år',
  },
  {
    id: 'five-years',
    months: 60,
    title: '5 år',
  },
  {
    id: 'eight-years',
    months: 96,
    title: '8 år',
  },
] as IDurationOptions[];

export const durationLeasingOptions = [
  {
    id: 'one-years',
    months: 12,
    title: '1 år',
  },
  {
    id: 'two-years',
    months: 24,
    title: '2 år',
  },
  {
    id: 'three-years',
    months: 36,
    title: '3 år',
  },
  {
    id: 'four-years',
    months: 48,
    title: '4 år',
  },
  {
    id: 'five-years',
    months: 60,
    title: '5 år',
  },
] as IDurationOptions[];

export const getMonths = (selectedId: string) =>
  allDurationOptions.find(({ id }) => id === selectedId)?.months || 0;

export const getYears = (selectedId: string) =>
  allDurationOptions.find(({ id }) => id === selectedId)?.years || 0;

export const getIdFromYears = (years: number) => {
  const durationItem = allDurationOptions.find((item) => item.years === years);
  return durationItem?.id;
};

export const DURATION_DEFAULT = getMonths('three-years');

export const getResidualPercent = (
  duration: number,
  loanType: LoanType,
  type: string = VehicleType.Car
): number => {
  if (loanType === LoanType.Ecster) {
    if (type === VehicleType.ATV || type === VehicleType.Camper) {
      // if (duration <= getMonths('four-years')) return 0.65;
      // if (duration <= getMonths('five-years')) return 0.6;
      return 0;
    }

    if (
      type === VehicleType.Car ||
      type === VehicleType.MC ||
      type === VehicleType.Jetski ||
      type === VehicleType.Smowmobile
    ) {
      return duration <= DURATION_DEFAULT ? 0 : 0; // Remove residual value for now
    }
    return 0;
  }

  if (loanType === LoanType.NordeaLoan || loanType === LoanType.NordeaLeasing) {
    if (type === VehicleType.Camper) {
      if (duration <= getMonths('three-years')) return 0.65;
      if (duration <= getMonths('four-years')) return 0.6;
      if (duration <= getMonths('five-years')) return 0.55;
      if (duration <= getMonths('six-years')) return 50;
      if (duration <= getMonths('seven-years')) return 40;
    }
    if (duration <= getMonths('one-years')) return 0.75;
    if (duration <= getMonths('two-years')) return 0.65;
    if (duration <= getMonths('three-years')) return 0.55;
    if (loanType === LoanType.NordeaLoan) {
      if (duration <= getMonths('four-years')) return 0.45;
      if (duration <= getMonths('five-years')) return 0.3;
      if (duration <= getMonths('six-years')) return 0;
    }
  }

  return 0;
};
