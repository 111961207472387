import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import styles from './InputText.module.scss';

interface InputTextProps {
  name: string;
  registerOptions?: any;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  size?: 'large' | 'medium';
  inline?: boolean;
  type?: string;
  isCouponValid?: boolean;
  uppercase?: boolean;
  options?: {
    min?: number;
    max?: number;
    step?: number;
    maxlength?: number;
    required?: boolean;
  };
}

export const InputText: React.FC<InputTextProps> = ({
  name,
  registerOptions = {},
  label,
  placeholder = '',
  disabled,
  size = 'large',
  inline,
  type = 'text',
  isCouponValid,
  uppercase,
  options,
  ...rest
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const value = watch(name);

  return (
    <div
      className={`${styles.container} ${styles[size]} ${inline ? styles.inline : ''} ${
        uppercase ? styles.upperCase : ''
      }  `}
    >
      <input
        {...register(name, registerOptions)}
        type={type}
        id={name}
        name={name}
        spellCheck="false"
        placeholder={placeholder}
        autoComplete="off"
        disabled={disabled}
        className={`${value ? styles.value : ''} ${errors[name] ? styles.error : ''} ${
          isCouponValid ? styles.greenBorder : ''
        }`}
        {...options}
        {...rest}
      />
      <label htmlFor={name} className={value && styles.value}>
        {label}
      </label>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
      />
    </div>
  );
};
