import { ErrorMessage } from '@hookform/error-message';
import React, { ReactNode, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import useOnClickInside from 'utils/useOnClickInside';

import styles from './InputCheckbox.module.scss';

export enum InputCheckboxSize {
  Large = 'large',
  Small = 'small',
}

interface InputCheckboxProps {
  name: string;
  registerOptions?: any;
  label: string | ReactNode;
  value?: string;
  disabled?: boolean;
  size?: InputCheckboxSize;
  onChange?: React.ChangeEventHandler;
  checked?: boolean;
}

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  name,
  registerOptions = {},
  value = 'true',
  disabled = false,
  label,
  size = InputCheckboxSize.Large,
  onChange,
  checked = false,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const nameValue = watch(name);
  const ref = useRef(name) as unknown as React.MutableRefObject<HTMLInputElement>;
  const handleClickInside = () => {
    if (!nameValue) {
      setValue(name, value);
    } else {
      setValue(name, undefined);
    }
  };

  useOnClickInside(ref, handleClickInside);

  return (
    <>
      <div className={`${styles.checkbox} ${disabled ? styles.disabled : ''}`}>
        <input
          {...register(name, registerOptions)}
          type="checkbox"
          id={name}
          ref={ref}
          name={name}
          disabled={disabled}
          value={value}
          className={errors[name] ? styles.error : ''}
          onChange={onChange}
          defaultChecked={checked}
        />
        <span className={styles[size]}>{label}</span>
      </div>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
      />
    </>
  );
};
