import React, { useRef } from 'react';
import ReactSlider from 'react-slider';

import { numberToFormatedString } from 'utils/numberToFormatedString';

import { Input } from './Inputs';
import styles from './InputSlider.module.scss';

interface Props {
  min: number;
  max: number;
  step?: number;
  value: number;
  label?: string;
  onChange?: (value: number) => void;
  suffix?: string;
  className?: string;
  size?: 'small' | 'large';
  limits?: number[];
  isInverted?: boolean;
  inputProps?: any;
  sliderMin?: number;
  sliderMax?: number;
  sliderValue?: number;
  sliderSuffix?: string;
  onSliderChange?: (value: number) => void;
}

const InputSlider = ({
  min = 0,
  max,
  step = 1,
  value,
  label = '',
  onChange = () => {},
  suffix,
  className = '',
  size = 'large',
  limits,
  isInverted = false,
  inputProps = {},
  sliderMin,
  sliderMax,
  sliderValue,
  sliderSuffix,
  onSliderChange,
}: Props) => {
  const lastValue = useRef<number | null>(null);
  const rootClassnames = `${styles.root} ${
    inputProps.disabled ? styles.disabled : ''
  } ${className} ${isInverted ? styles.inverted : ''}`;

  const handleChange = (newValue: number) => {
    const minValue = sliderMin || min;
    if (typeof newValue !== 'number') return;

    lastValue.current = newValue;
    if (newValue < minValue) return;

    if (onSliderChange) {
      onSliderChange(newValue);
    } else onChange(newValue);
  };

  const handleInputChange = (newValue: number) => {
    lastValue.current = newValue;
    if (typeof newValue !== 'number' || newValue < min) return;

    if (inputProps.onChange) inputProps.onChange(newValue);
    else onChange(newValue);
  };

  const handleBlur = () => {
    if (!lastValue.current || lastValue.current < min) {
      if (inputProps.onChange) inputProps.onChange(min);
      else onChange(min);
    }
  };

  return (
    <div className={rootClassnames}>
      <div className={`${styles.inputWrapper} `}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        <Input
          id={label}
          type="number"
          onBlur={handleBlur}
          className={`${styles.input} ${styles[size]}`}
          suffix={<span className={styles.suffix}>{suffix}</span>}
          max={max}
          value={value}
          thousandSeparator=" "
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          min={0}
          onChange={handleInputChange}
        />
      </div>
      <ReactSlider
        ariaLabel={label}
        className={styles.slider}
        trackClassName={styles.track}
        thumbClassName={styles.thumb}
        min={sliderMin || min}
        max={sliderMax || max}
        value={sliderValue || value}
        onChange={handleChange}
        step={step}
      />
      <div className={styles.limitsWrapper}>
        {limits ? (
          <>
            <span className={styles.limit}>{limits[0]}</span>
            <span className={styles.limit}>{limits[1]}</span>
          </>
        ) : (
          <>
            <span className={styles.limit}>
              {numberToFormatedString(sliderMin || min)} {sliderSuffix || suffix}
            </span>
            <span className={styles.limit}>
              {numberToFormatedString(sliderMax || max)} {sliderSuffix || suffix}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default InputSlider;
