export const loanDataLayerPush = (
  status: string,
  amount: string,
  event: string = 'application-submitted'
) => {
  // @ts-ignore
  if (typeof window !== 'undefined' && window.dataLayer) {
    let realStatus = 'incomplete';
    if (status === 'ok') {
      realStatus = 'yes';
    } else if (status === 'notOk') {
      realStatus = 'no';
    }

    // @ts-ignore
    window.dataLayer.push({
      accepted: realStatus,
      amount,
      interest: process.env.NEXT_PUBLIC_LOAN_INTEREST,
      event,
    });
  }
};
