import React, { useRef } from 'react';

import { Input } from '../InputSliderNew/Inputs';

import styles from './RangePicker.module.scss';

const RangePicker = ({
  min = 0,
  max,
  step = 1,
  value,
  label = '',
  onChange = () => {},
  suffix,
  className = '',
  size = 'large',
  isInverted = false,
  inputProps = {},
}: any) => {
  const lastValue = useRef(null);
  const handleInputChange = (newValue: number) => {
    lastValue.current = value;
    if (typeof newValue !== 'number' || newValue < min || newValue > max) return;

    if (inputProps.onChange) inputProps.onChange(newValue);
    else onChange(newValue);
  };

  const handleBlur = () => {
    if (!lastValue.current || lastValue.current < min) {
      if (inputProps.onChange) inputProps.onChange(min);
      else onChange(min);
    }
  };

  const handleIncrement = () => {
    handleInputChange(value + step);
  };

  const handleDecrement = () => {
    handleInputChange(value - step);
  };

  return (
    <div className={`${styles.root} ${className} ${isInverted ? styles.inverted : ''}`}>
      <div className={`${styles.inputWrapper} `}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        <div className={styles.inputGroup}>
          <button type="button" className={styles.inputSymbol} onClick={handleDecrement}>
            -
          </button>
          <Input
            id={label}
            type="number"
            onBlur={handleBlur}
            className={`${styles.input} ${styles[size]}`}
            suffix={<span className={styles.suffix}>{suffix}</span>}
            max={max}
            value={value}
            thousandSeparator=" "
            // eslint-disable-next-line
            {...inputProps}
            min={min}
            onChange={handleInputChange}
          />
          <button type="button" className={styles.inputSymbol} onClick={handleIncrement}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default RangePicker;
