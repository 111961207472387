export const inputBorderStyling = [
  {
    background: `
          linear-gradient(to right,#01fa9a 0,#01fa9a 1ch,transparent 1ch,transparent 1.5ch,#a5a5a5 1.5ch,#a5a5a5 2.5ch,transparent 2.5ch,transparent 3ch,#a5a5a5 3ch,#a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#a5a5a5 7.5ch,#a5a5a5 8.5ch,transparent 8.5ch,transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#01fa9a 1.5ch, #01fa9a 2.5ch,transparent 2.5ch, transparent 3ch,#a5a5a5 3ch, #a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#a5a5a5 7.5ch, #a5a5a5 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#a5a5a5 1.5ch, #a5a5a5 2.5ch,transparent 2.5ch, transparent 3ch,#01fa9a 3ch, #01fa9a 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#a5a5a5 7.5ch, #a5a5a5 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#a5a5a5 1.5ch, #a5a5a5 2.5ch,transparent 2.5ch, transparent 3ch,#a5a5a5 3ch, #a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#01fa9a 4.5ch, #01fa9a 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#a5a5a5 7.5ch, #a5a5a5 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#a5a5a5 1.5ch, #a5a5a5 2.5ch,transparent 2.5ch, transparent 3ch,#a5a5a5 3ch, #a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#01fa9a 6ch, #01fa9a 7ch,transparent 7ch, transparent 7.5ch,#a5a5a5 7.5ch, #a5a5a5 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#a5a5a5 1.5ch, #a5a5a5 2.5ch,transparent 2.5ch, transparent 3ch,#a5a5a5 3ch, #a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#01fa9a 7.5ch, #01fa9a 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
  {
    background: `
          linear-gradient(to right,#a5a5a5 0,#a5a5a5 1ch,transparent 1ch, transparent 1.5ch,#a5a5a5 1.5ch, #a5a5a5 2.5ch,transparent 2.5ch, transparent 3ch,#a5a5a5 3ch, #a5a5a5 4ch,transparent 4ch, transparent 4.5ch,#a5a5a5 4.5ch, #a5a5a5 5.5ch,transparent 5.5ch, transparent 6ch,#a5a5a5 6ch, #a5a5a5 7ch,transparent 7ch, transparent 7.5ch,#01fa9a 7.5ch, #01fa9a 8.5ch,transparent 8.5ch, transparent 9ch)
          0 100%/100% 8px no-repeat`,
  },
];
