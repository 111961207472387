import React from 'react';
import { useFormContext, FieldValues, RegisterOptions } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import styles from './InputSelect.module.scss';

interface InputSelectProps {
  name: string;
  registerOptions?: RegisterOptions<FieldValues, string>;
  defaultText?: string;
  options: string[];
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  inline?: boolean;
}

export const InputSelect: React.FC<InputSelectProps> = ({
  name,
  registerOptions,
  defaultText,
  options,
  disabled,
  size = 'large',
  inline,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FieldValues>();

  return (
    <div className={`${styles.container} ${styles[size]} ${inline ? styles.inline : ''}`}>
      <select
        {...register(name, registerOptions)}
        id={name}
        name={name}
        disabled={disabled}
        className={`${errors[name] ? styles.error : ''}`}
        defaultValue=""
        {...rest}
      >
        {/* eslint-disable-next-line */}
        <option disabled value="" className={styles.disabledOption}>
          {!defaultText ? 'Välj ett alternativ' : 'Välj'}
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <label htmlFor={name} className={styles.value}>
        {defaultText}
      </label>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
      />
    </div>
  );
};
