/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck todo: fix typescript errors and remove this lien
import React, { useState, useRef } from 'react';
import Image from 'next/image';
import NumberFormat from 'react-number-format';

import styles from './Inputs.module.scss';

export const Input = ({
  className = '',
  value,
  max,
  min = 0,
  regex,
  maxLength,
  type,
  error,
  stateless = false,
  inputClassName = '',
  id,
  onChange = () => {},
  onBlur = () => {},
  getValidationStatus = () => {},
  // eslint-disable-next-line
  clear = false,
  suffix,
  prefix,
  valuePrefix,
  valueSuffix,
  getRef,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const input = useRef({});

  const handleClick = () => input.current.focus();

  // eslint-disable-next-line
  const handleChange = ({ target: { value } }) => {
    if (maxLength) {
      const valueLength = String(value).length;
      if (valueLength === maxLength) {
        if (regex) getValidationStatus(regex.test(String(value)));
      } else if (valueLength > maxLength) return;
    }

    onChange(value);
  };

  const handleNumberChange = ({ floatValue }) => {
    if (maxLength) {
      const valueLength = String(floatValue).length;

      if (valueLength === maxLength && regex) getValidationStatus(regex.test(String(floatValue)));
      else if (valueLength > maxLength) return false;

      onChange(floatValue);
    } else {
      let finalValue = floatValue;

      if (max && finalValue > max) finalValue = max;
      else if (min && finalValue < min) finalValue = min;

      onChange(finalValue);

      if (finalValue !== floatValue) return false;
    }

    return true;
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  const setRef = (inputRef) => {
    input.current = inputRef;
    // eslint-disable-next-line
    if (getRef) {
      // eslint-disable-next-line
      getRef.current = inputRef;
    }
  };

  if (suffix || prefix)
    return (
      <>
        {/* eslint-disable-next-line */}
        <div
          onClick={handleClick}
          className={`${className} ${styles.afixWrapper} ${isFocused ? styles.focused : ''} ${
            stateless ? styles.stateless : ''
          } ${error ? styles.inputError : ''}`}
        >
          {prefix}
          {type === 'number' ? (
            <NumberFormat
              {...(id && { id })}
              getInputRef={setRef}
              type="tel"
              value={value}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              className={`${styles.wrappedInput} ${inputClassName}`}
              isAllowed={handleNumberChange}
              prefix={valuePrefix}
              suffix={valueSuffix}
              {...props}
            />
          ) : (
            <input
              {...(id && { id })}
              ref={setRef}
              type={type}
              value={value}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              className={`${styles.wrappedInput} ${inputClassName}`}
              onChange={handleChange}
              {...props}
            />
          )}
          {suffix}
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </>
    );

  return (
    <div className={className}>
      {type === 'number' ? (
        <NumberFormat
          {...(id && { id })}
          type="tel"
          value={value}
          className={`${styles.simpleInput} ${error ? styles.inputError : ''}  ${inputClassName}`}
          prefix={valuePrefix}
          suffix={valueSuffix}
          onBlur={onBlur}
          isAllowed={handleNumberChange}
          getInputRef={setRef}
          {...props}
        />
      ) : (
        <input
          {...(id && { id })}
          ref={setRef}
          value={value}
          type={type}
          onBlur={onBlur}
          className={`${styles.simpleInput} ${error ? styles.inputError : ''}  ${inputClassName}`}
          onChange={handleChange}
          {...props}
        />
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export const ClearNumberInput = ({ className = '', inputClassName = '', ...props }) => (
  <Input
    className={`${styles.noStyle} ${className}`}
    inputClassName={`text-center ${styles.noStyle} ${inputClassName}`}
    thousandSeparator=" "
    type="number"
    {...props}
  />
);

export const PriceInput = ({ placeholder = 'Fyll i pris här', ...props }) => (
  <Input
    type="number"
    thousandSeparator=" "
    max={1500000}
    valueSuffix=" kr"
    placeholder={placeholder}
    {...props}
  />
);

export const EmailInput = ({ placeholder = 'Din e-post', ...props }) => (
  <Input type="email" placeholder={placeholder} {...props} />
);

export const PhoneInput = ({
  placeholder = 'Ditt mobilnummer',
  onChange = () => {},
  withFlag,
  ...props
}) => (
  <Input
    {...(withFlag
      ? {
          prefix: (
            <Image
              width={30}
              height={19}
              layout="intrinsic"
              src="/images/SwedishFlag.svg"
              alt="Sweden flag"
            />
          ),
        }
      : {})}
    onValueChange={(formatedValue) => {
      onChange(formatedValue.value);
    }}
    placeholder={placeholder}
    format="###-### ## ###"
    type="number"
    {...props}
  />
);

export const SSNInput = ({ placeholder = 'ÅÅÅÅMMDDNNNN', withLogo, ...props }) => (
  <Input
    {...(withLogo
      ? {
          prefix: (
            <Image
              width={30}
              height={30}
              layout="intrinsic"
              src="/images/partners/BankidLogo.svg"
              alt="BankID"
            />
          ),
        }
      : {})}
    placeholder={placeholder}
    format="############"
    type="number"
    regex={/^(19|20)?\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))\d{4}$/}
    maxLength={12}
    {...props}
  />
);

export const CarNumberInput = (props) => (
  <Input regex={/[A-Za-z]{3}[0-9]{2}[A-Za-z0-9]{1}/g} type="text" maxLength={6} {...props} />
);

export const OrganisationNumberInput = (props) => (
  <Input type="number" maxLength={10} format="SE-##########" {...props} />
);

export const CampaignCodeInput = ({ placeholder = 'Kampanjkod', ...props }) => (
  <Input type="text" placeholder={placeholder} {...props} />
);
