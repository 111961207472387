import React from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './InputSwitch.module.scss';

interface InputSwitchProps {
  name: string;
  disabled?: boolean;
  label?: string;
}

export const InputSwitch: React.FC<InputSwitchProps> = ({ name, disabled = false, label }) => {
  const { register } = useFormContext();

  return (
    <div className={styles.switch}>
      {label ?? <span>{label}</span>}
      <input {...register(name)} type="checkbox" id={name} disabled={disabled} />
      <label htmlFor={name}>Toggle</label>
    </div>
  );
};
